import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendEmail = createAsyncThunk(
    "sendEmail",
    async (data) => {
        const response = await fetch(
            `https://api.brilljust.com/send-email`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);
export const sendEmisReoprtAction = createAsyncThunk(
    "sendMisEmail",
    async (data) => {
        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_getEmailIndividualsMis?start_date=${data?.start_date}&end_date=${data?.end_date}&email=${data.email}`,
            {
                method: "GET",

                headers: new Headers({
                    'Authorization': `${data?.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);