import React, { useState, useEffect,useRef} from "react";
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { Button, Stack, Typography } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  TextField,
  TableSortLabel,
  IconButton,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./DataTable.css";

const DataTable = ({ columns, getTransactionListTotalCount, data, page, setPage, filterText, setFilterText, setSerachQuery, searachQuery, timeoutRef, rowsPerPage, setRowsPerPage, newLoder, setNewLoadi, setSearchQueryFlag }) => {
  const [sortBy, setSortBy] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState("asc");
  const [isFirstRender, setIsFirstRender] = useState(true); // Track first render

  let totalCount = getTransactionListTotalCount?.count ? getTransactionListTotalCount?.count[0]?.total : 1
  const handleSort = (columnId) => {
    const isAsc = sortBy === columnId && sortDirection === "asc";
    setSortBy(columnId);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleNext = ((event, newPage) => {
    setPage(page + 1);
  })
  const handlePreview = (() => {
    setPage(page - 1);
  })
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const skeletonData = Array.from({ length: 10 }, () => ({
    Amount: true,
    Date: true,
    actions: true,
    insured: true,
    mobileNo: true,
    policyNumber: true,
    product: true,
  }));

  const filteredData = data?.filter((row) =>
    columns.some((column) => {
      const value = row[column.id];
      return (
        value !== undefined &&
        String(value).toLowerCase().includes(filterText.toLowerCase())
      );
    })
  );

  function Animations({ width, animation }) {

    return (
      <Box >
        <Skeleton animation={animation} sx={{ width: { width }, padding: "0.3rem" }} />
      </Box>
    );
  }
  const sortedData = sortBy
    ? filteredData.sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
    })
    : filteredData;

  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    const timer = setTimeout(() => {
      setSearchQueryFlag((prev) => !prev)
      setPage(0)
    }, 1300);
    return () => clearTimeout(timer);
  }, [searachQuery]);


  return (
    // <>
    //   <div className="dataTable-container">

    //     <div className="heading-container">
    //       <p>Transactions</p>
    //       <TextField
    //         variant="outlined"
    //         label="Search"
    //         value={searachQuery}
    //         onChange={(e) => {
    //           setSerachQuery(e.target.value)
    //         }}
    //         style={{ margin: "5px", width: "17rem" }}
    //         InputProps={{
    //           endAdornment: (
    //             <IconButton>
    //               <SearchIcon />
    //             </IconButton>
    //           ),
    //         }}
    //       />
    //     </div>

    //     <TableContainer>
    //       <Table stickyHeader aria-label="sticky table">
    //         <TableHead>
    //           <TableRow>{
    //             columns.map((column) => (
    //               <TableCell
    //                 key={column.id}
    //                 style={{
    //                   background: "#EDEBFF",
    //                   position: "sticky",
    //                   top: 0,
    //                   zIndex: 1,
    //                 }}
    //               >
    //                 <TableSortLabel
    //                   active={sortBy === column.id}
    //                   direction={sortBy === column.id ? sortDirection : "asc"}
    //                   onClick={() => handleSort(column.id)}
    //                   sx={{
    //                     "& .MuiTableSortLabel-icon": {
    //                       color: "#60646A !important",
    //                       visibility: "visible",
    //                       opacity: "inherit !important",
    //                     },
    //                   }}
    //                 >
    //                   {column.label}
    //                 </TableSortLabel>
    //               </TableCell>
    //             ))
    //           }
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>

    //           {newLoder && skeletonData?.map((row) => (
    //             <TableRow key={row.id}>
    //               {columns.map((column) => (

    //                 <TableCell key={column.id}>
    //                   <Animations animation="wave" width="90px" />
    //                 </TableCell>
    //               ))}
    //             </TableRow>
    //           ))}


    //           {!newLoder && sortedData?.map((row) => (
    //             <TableRow key={row.id}>
    //               {columns.map((column) => (

    //                 <TableCell key={column.id}>{row[column.id]}</TableCell>
    //               ))}
    //             </TableRow>
    //           ))}
    //         </TableBody>
    //         {!newLoder && sortedData?.length == 0 &&
    //           <h2 style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "1rem" }}>No Data Found</h2>
    //         }
    //       </Table>
    //     </TableContainer>

    //     <Stack spacing={2} alignItems="center">
    //       <Typography>Page: {page + 1}</Typography>
    //       <Pagination count={Math.ceil(totalCount / rowsPerPage)} page={page + 1} onChange={handleChangePage} />
    //       <Stack direction="row" spacing={2}>
    //       </Stack>
    //     </Stack>
    //   </div>
    // </>

    <>
  <div className="dataTable-container">

    <div className="heading-container">
      <p>Transactions</p>
      <TextField
        variant="outlined"
        label="Search"
        value={searachQuery}
        onChange={(e) => setSerachQuery(e.target.value)}
        style={{ margin: "5px", width: "17rem" }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div>

    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                style={{
                  background: "#EDEBFF",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  minWidth: 100, // Ensures uniform width
                  textAlign: column.id === 'date' ? 'center' : 'left' // Align date properly
                }}
              >
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortBy === column.id ? sortDirection : "asc"}
                  onClick={() => handleSort(column.id)}
                  sx={{
                    "& .MuiTableSortLabel-icon": {
                      color: "#60646A !important",
                      visibility: "visible",
                      opacity: "inherit !important",
                    },
                  }}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {newLoder && skeletonData?.map((row) => (
            <TableRow key={row.id} style={{ height: "60px" }}>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <Animations animation="wave" width="90px" />
                </TableCell>
              ))}
            </TableRow>
          ))}

          {!newLoder && sortedData?.map((row) => (
            <TableRow key={row.id} style={{ height: "60px" }}>
              {columns.map((column) => (
                <TableCell 
                  key={column.id}
                  style={{
                    textAlign: column.id === 'date' ? 'center' : 'left'
                  }}
                >
                  {row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

        {!newLoder && sortedData?.length === 0 && (
          <h2 style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem"
          }}>
            No Data Found
          </h2>
        )}
      </Table>
    </TableContainer>

    <Stack spacing={2} alignItems="center" style={{ marginTop: "1rem" }}>
      <Typography>Page: {page + 1}</Typography>
      <Pagination
        count={Math.ceil(totalCount / rowsPerPage)}
        page={page + 1}
        onChange={handleChangePage}
      />
      <Stack direction="row" spacing={2} />
    </Stack>
  </div>
</>

  );
};

export default DataTable;
