import React, { useEffect, useState } from 'react'
import Stepper from '../../../settper/Stepper'
import "./Calci.css"
import car from "../../../../Assets/Images/carLogo.svg"
import bike from "../../../../Assets/Images/bikelogo.svg"
import previewIcon from "../../../../Assets/Images/previewIcon.svg"
import nextIcon from "../../../../Assets/Images/NextIcon.svg"
import SearchSelect from '../../../InputField/Searchable-Dropdown/SearchSelect'
import Calender from '../../../InputField/Calender/Calender'
import InputFields from '../../../InputField/TextField/InputField'
import RadioButton from '../../../InputField/RadioButton/RadioButton'
import Button from '../../../InputField/Button/Button'
import PremiumBreakup from './PremiumCal'
import { fetchCalciAction, fetchCalciPremiumAction } from '../../../../Redux/Actions/fetchPassbookAction'
import { useDispatch, useSelector } from 'react-redux'
import { resetPremiumData } from '../../../../Redux/Reducers/PassBookReducer'
import ButtonLoader from '../../../Layouts/Loader/Button Loader/ButtonLoader'




const Calculator = () => {
    let initCalcidata = {
        vehicleType: "",
        fuel_type: "",
        cc_ranges: "",
        policy_type: "",
        registrationDate: "",
        idv: "",
        zone_type: "",
        claim: 0,
        ncbprecent: 0,
        discount: 0,
    }

    const dispatch = useDispatch()
    const userToken = useSelector((state) => {
        return state?.getLoggedInStatus?.data?.loginData?.token;
    });

    const [Selectedaddons, setAddons] = React.useState([])
    const [activeStep, setActiveStep] = React.useState(1)
    const [tpStepperVal, settpStepperVal] = React.useState(false)
    const [loading, setloading] = React.useState(false)
    const [CalciData, setCalciData] = useState(initCalcidata)
    const [errors, setErrors] = useState()


    const getCalciDataFun = (async () => {
        let bodData = { token: userToken }
        let data = await dispatch(fetchCalciAction(bodData))
    })
    useEffect(() => {
        getCalciDataFun()
    }, [])

    const CalciDataStore = useSelector((state) => {
        return state?.CalciData
    })
    let Data = CalciDataStore?.data?.data
    const fueltypeoption = Data?.fuelType?.map((item, index) => ({
        label: item.fuel_type,
        value: item.fuel_type
    }));
    const CCRangeOptins = Data?.ccRanges?.map((item) => ({
        label: item.cc_ranges,
        value: item.cc_ranges
    }))
    const policyTypeOptions = Data?.policyType?.map((item) => ({
        label: item?.policy_type,
        value: item?.policy_type
    }))
    const zoneOptions = Data?.zone?.map((item) => ({
        label: item?.zone_type,
        value: item?.zone_type
    }))
    const AddonsOptons = Data?.addonsType

    const HanldeFormData = ((e) => {
        const { name, value, checked } = e?.target || {};
        if (errors) {
            delete errors[name]
        }
        setCalciData((prev) => ({
            ...prev,
            [name]: value
        }))

    })
    const HanldeInputFormData = ((e) => {
        const { name, value, checked } = e?.target || {};
        if (errors) {
            delete errors[name]
        }
        setCalciData((prev) => ({
            ...prev,
            [name]: value
        }))
    })
    const validateVehicleDetails = () => {
        const newErrors = {};

        if (!CalciData?.fuel_type) {
            newErrors.fuel_type = "Fuel Type is Required";
        }

        if (!CalciData?.registrationDate) {
            newErrors.registrationDate = "Registration date required";
        }
        if (!CalciData?.cc_ranges) {
            newErrors.cc_ranges = "CC is required";
        }
        if (!CalciData?.policy_type) {
            newErrors.policy_type = "Policy type is required";
        }
        if (!CalciData?.idv) {
            newErrors.idv = "IDV is required";
        }
        if (!CalciData?.zone_type) {
            newErrors.zone_type = "Zone is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const HandldeSelectCalciData = ((value, name) => {
        if (errors) {
            delete errors[name]
        }
        if (name == "policy_type" && value == "Only TP 1TP") {
            settpStepperVal(true)

        } else if (name == "policy_type" && value !== "Only TP 1TP") {
            settpStepperVal(false)

        }

        setCalciData((prev) => ({
            ...prev,
            [name]: value
        }))


    })
    const handleCopiedDetails = ((ncb) => {
        setCalciData((prev) => ({
            ...prev,
            ncbprecent: ncb
        }))
    })

    const handleTreuAddons = ((val, falg) => {
        setAddons((prev) =>
            prev?.includes(val) && falg == "false"
                ? prev?.filter((item) => item !== val)
                : [...prev, val] // Add if not exists
        );
    })
    const handleClaimFalg = ((flag) => {
        setCalciData((prev) => ({
            ...prev,
            claim: flag == "true" ? 1 : 0
        }))
        if (flag == "true") {
            setCalciData((prev) => ({
                ...prev,
                ncbprecent: "0"
            }))
        }
    })
    const getPremiumVal=(async()=>{
        setloading(true)
        let newData = new Date(CalciData?.registrationDate)?.toISOString()
        let data = { data: { ...CalciData, Selectedaddons, registrationDate: newData }, token: userToken }
        let res = await dispatch(fetchCalciPremiumAction(data))
        setloading(false)
        if (res?.payload?.data['premium breakup']["Total Premium"]) {
            setActiveStep("getPremium")

        } else {
            alert("Something went wrong")
            handleResetPremium()
        }
    })
    const HandleCalPremium = ( () => {
        if(CalciData?.policy_type=="Only TP 1TP"){
            let valid=validateVehicleDetails()
            if(valid){
                getPremiumVal()
            }
        }else{
            getPremiumVal()
        }
       
    })
    const HanldeStepperVal = (() => {
        if(activeStep==1){
            let flag=validateVehicleType()
            if(flag){
                setActiveStep((prev) => prev + 1) 
            }
        }
      else if (activeStep == 2) {
            let flag = validateVehicleDetails()
            flag && setActiveStep((prev) => prev + 1)
        } else {
            setActiveStep((prev) => prev + 1)
        }

    })
    const handleResetPremium = (() => {
        setActiveStep(1)
        setAddons([])
        settpStepperVal(false)
        setCalciData(initCalcidata)
        dispatch(resetPremiumData())
    })
    const validateVehicleType= () => {
        const newErrors = {};

        if (!CalciData?.vehicleType) {
            newErrors.vehicleType = "Please Select Vechile Type";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleValidVechicleType=((val)=>{
        
             if(errors){
                delete errors?.vehicleType
             }
            setCalciData((prev) => ({ ...prev, vehicleType: val }))
        
        
    })
    return (
        <>
            {activeStep == "getPremium" ? <PremiumBreakup vehicleInfo={CalciData} handleResetPremium={handleResetPremium} /> :
                <div className='Calci_Container'>
                    <h2>Motor Insurance Premium Calculator</h2>
                    <Stepper activeStep={activeStep} setActiveStep={setActiveStep} validateVehicleDetails={validateVehicleDetails} />
                    {activeStep == 1 &&
                        <div className='calci_box'>
                            <div>

                                <p>Select Vehicle Type</p>
                                <div className='vechileType'>
                                    { CalciDataStore?.data?.data?.vehicleType&&CalciDataStore?.data?.data?.vehicleType.map((data) => {

                                        return (
                                            <div onClick={() =>handleValidVechicleType(data?.vehicleType) } id={CalciData?.vehicleType === data?.vehicleType ? "Seclected_VechileType" : ""}>
                                                <img src={data?.vehicle_img} alt='' height={30} width={30} />
                                                <p style={{marginLeft:"5px"}}>{data?.vehicleType}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                {errors?.vehicleType && <p style={{color:"red"}}>{errors.vehicleType}</p>}
                            </div>

                        </div>
                    }
                    {activeStep == 2 &&
                        <div className='basic_details_conatiner'>
                            <div className="input-container-form" id='Basic_details'>
                                <SearchSelect
                                    optionList={fueltypeoption}
                                    handleSelect={(e) => HandldeSelectCalciData(e.value, "fuel_type")}
                                    placeholder="Select Fuel Type"
                                    value={CalciData?.fuel_type}
                                    label="Select Fuel Type"
                                    name="fuel_type"
                                    errState={errors?.fuel_type ? true : false}
                                    errMsg={errors?.fuel_type}
                                    errClassName="input-err"
                                />
                                <Calender
                                    className="pForm_datePicker"
                                    placeholder="Date of Registration"
                                    name="registrationDate"
                                    id="dob"
                                    value={CalciData?.registrationDate}
                                    changeEvent={(e) => HanldeFormData(e)}
                                    errState={errors?.registrationDate ? true : false}
                                    errMsg={errors?.registrationDate}
                                    errClassName={errors?.registrationDate ? "input-err" : ""}
                                />

                            </div>
                            <div className="input-container-form">
                                <SearchSelect
                                    optionList={CCRangeOptins}
                                    handleSelect={(e) => HandldeSelectCalciData(e.value, "cc_ranges")}
                                    placeholder="Select CC"
                                    value={CalciData?.cc_ranges}
                                    label="CC"
                                    name="cc_ranges"
                                    errState={errors?.cc_ranges ? true : false}
                                    errMsg={errors?.cc_ranges}
                                    errClassName="input-err"
                                />

                                <SearchSelect
                                    optionList={policyTypeOptions}
                                    value={CalciData?.policy_type}
                                    handleSelect={(e) => HandldeSelectCalciData(e.value, "policy_type")}
                                    placeholder="Select Policy Type"
                                    label="Select Policy Type"
                                    name="policy_type"
                                    errState={errors?.policy_type ? true : false}
                                    errMsg={errors?.policy_type}
                                    errClassName="input-err"
                                />


                            </div>
                            <div className="input-container-form">
                                <InputFields
                                    type="number"
                                    placeholder="IDV (Insured Declared Value)"
                                    name="idv"
                                    className=""
                                    onChangeEvent={(e) => HanldeInputFormData(e)}
                                    value={CalciData?.idv}
                                    errState={errors?.idv ? true : false}
                                    errMsg={errors?.idv}
                                    errClassName="input-err"
                                />
                                <SearchSelect
                                    optionList={zoneOptions}
                                    handleSelect={(e) => HandldeSelectCalciData(e.value, "zone_type")}
                                    placeholder="Select Zone"
                                    value={CalciData?.zone_type}
                                    label="Zone"
                                    name="zone_type"
                                    errState={errors?.zone_type ? true : false}
                                    errMsg={errors?.zone_type}
                                    errClassName="input-err"
                                />
                            </div>


                        </div>
                    }
                    {activeStep == 3 &&
                        <div className='addons_container'>
                            <div className='addons_box'>
                                <div >{AddonsOptons?.map((data, key) => {
                                    return (
                                        <div className='addons_felx'>
                                            <div>{data?.addons_type}</div>
                                            <div className="radio-container-cardetails">
                                                <div className="switch-container-modal">
                                                    <div className="switchBtn-container" >
                                                        <button className={Selectedaddons?.includes(data?.addons_type) ? "active-switchclass" : "Non-active-switch-class"} id="activeStwitchbtn" onClick={() => handleTreuAddons(data?.addons_type, "true")} >Yes</button>
                                                        <button className={!Selectedaddons?.includes(data?.addons_type) ? "active-switchclass" : "Non-active-switch-class"} id="noactiveStwitchbtn" onClick={() => handleTreuAddons(data?.addons_type, "false")} >No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                                }</div>

                            </div>
                        </div>
                    }
                    {activeStep == 4 &&
                        <div className='nab_discount_conatainer'>

                            <div >
                                <p>Did you made any claim?</p>
                                <div className="radio-container-cardetails">
                                    <div className="switch-container-modal">
                                        <div className="switchBtn-container" >
                                            <button className={CalciData?.claim ? "active-switchclass" : "Non-active-switch-class"} id="activeStwitchbtn" onClick={() => handleClaimFalg("true")} >Yes</button>
                                            <button className={!CalciData?.claim ? "active-switchclass" : "Non-active-switch-class"} id="noactiveStwitchbtn" onClick={() => handleClaimFalg("false")} >No</button>
                                        </div>
                                    </div>
                                </div>
                                <p>Select NCB (%)</p>
                                <div className="ncb-btn-editform">
                                    <Button
                                        text="0%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == "0"
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails(0, "prevNcbCopy", "20");
                                        }}
                                    />
                                    <Button
                                        text="20%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == "20"
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails("20", "prevNcbCopy", "25");
                                        }}
                                    />
                                    <Button
                                        text="25%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == "25"
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails("25", "prevNcbCopy", "35");
                                        }}
                                    />
                                    <Button
                                        text="35%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == 35
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails("35", "prevNcbCopy", "45");
                                        }}
                                    />
                                    <Button
                                        text="45%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == 45
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails("45", "prevNcbCopy", "50");
                                        }}
                                    />
                                    <Button
                                        text="50%"
                                        isdisable={CalciData?.claim ? true : false}
                                        className={
                                            CalciData?.ncbprecent == 50
                                                ? "activeNcbBtn ncbBtn-editForm"
                                                : "ncbBtn-editForm"
                                        }
                                        event={(e) => {
                                            handleCopiedDetails("50", "prevNcbCopy", "50");
                                        }}
                                        name="prevNcbCopy"
                                        value="50"
                                    />
                                </div>
                                <div className='special_Discount'>
                                    <p>Discount</p>
                                    <input type='text' className='inputFilded_discount' value={CalciData?.discount} name='discount' onChange={(e) => setCalciData((prev) => ({ ...prev, discount: e.target.value }))} />

                                </div>

                            </div>
                        </div>
                    }
                    <div className='formBtnBox'>
                        {activeStep !== 1 ?
                            <Button
                                text="Preview"
                                icons={previewIcon}
                                className="secondry_btn btn-calci "
                                event={() => setActiveStep((prev) => prev - 1)}
                            />
                            : <span></span>}

                        {activeStep !== "getPremium" && !loading ?

                            <Button
                                text={(activeStep == 4 || tpStepperVal) ? "Get Premium" : "Next"}
                                icons={(activeStep !== 4 && !tpStepperVal) ? nextIcon : ""}
                                name="nextCalci"
                                event={() => (activeStep == 4 || tpStepperVal) ? HandleCalPremium() : HanldeStepperVal()}
                                className="button btn-calci"
                            /> :
                            <ButtonLoader className="button btn-calci" />
                        }


                    </div>

                </div>

            }
        </>


    )
}

export default Calculator