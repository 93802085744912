import React, { useEffect, useState } from 'react'
import Calender from '../../../InputField/Calender/Calender'
import Button from '../../../InputField/Button/Button'
import ButtonLoader from '../../../Layouts/Loader/Button Loader/ButtonLoader'
import { fetchPassbookTransaction, } from '../../../../Redux/Actions/fetchPassbookAction'
import { useDispatch, useSelector } from 'react-redux'
import { resetTransactionList } from '../../../../Redux/Reducers/PassBookReducer'
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';

import Box from '@mui/material/Box';
import { Stack, Typography } from "@mui/material";

import moment from 'moment';
import "./passbook.css"

const Passbook = ({ passbookdata, setPassbookData, errors, setErrors }) => {

    const passBookDataT = useSelector((data) => {
        return data?.PassbookTransactionData
    })

    let totalCount = passBookDataT?.data?.data?.count ? passBookDataT?.data?.data?.count[0] : 1
    const [min, setMin] = useState(false)
    const [activeTab, setActiveTab] = useState("1M");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [resetdateFlag, setResetDateFlag] = useState(false)
    const [page, setPage] = useState(0)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date(passbookdata.start_date));
    const userToken = useSelector((state) => {
        return state?.getLoggedInStatus?.data?.loginData?.token;
    });
    const getDateRange = (filter) => {
        const currentDate = moment();
        if (filter === "1M") return currentDate.subtract(1, "month").format("YYYY-MM-DD");
        if (filter === "3M") return currentDate.subtract(3, "month").format("YYYY-MM-DD");
        if (filter === "6M") return currentDate.subtract(6, "month").format("YYYY-MM-DD");
        return "";
    };
    React.useEffect(() => {
        if (passbookdata?.start_date && !isNaN(new Date(passbookdata.start_date))) {
            const startDate = new Date(passbookdata.start_date);
            const maxDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1)); // 1 year ahead
            const today = new Date();
            console.log(maxDate, today, "today");
            console.log(maxDate == today, "today");


            // Ensuring the max date does not exceed today
            setSelectedDate(maxDate > today ? today : maxDate);
            setMin(passbookdata.start_date);

            // Set min date to selected date only if max date is in the future

            // if (maxDate > today) {
            //     alert(true)

            // }
        } else {
            setSelectedDate(null);
        }
    }, [passbookdata.start_date]);

    // React.useEffect(() => {

    //     if (selectedDate && !isNaN(new Date(selectedDate))) {
    //         const newMaxDate = new Date(new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 365));
    //         const today = new Date();    
    //         setSelectedDate(newMaxDate > today ? today : newMaxDate);
    //         if (newMaxDate >today) {
    //             setMin(selectedDate)
    //         }

    //     } else {
    //         setSelectedDate(null)
    //     }
    // }, [passbookdata.start_date]);
    const validateMisfield = () => {
        const newErrors = {};
        if (!passbookdata.start_date) {
            newErrors.misStartDate = "Start Date is required";
        }
        if (!passbookdata.end_date) {
            newErrors.misEndDate = "End Date is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };


    function formatDate(dateString) {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
        const day = String(date.getDate()).padStart(2, '0');        // Add leading zero

        return `${year}-${month}-${day}`;
    }
    useEffect(() => {
        let ans = getDateRange(activeTab)

    }, [activeTab, page, resetdateFlag])

    const HanldeFormData = ((e) => {
        const { name, value, checked } = e?.target || {};
        if (name == "misStartDate") {
            setSelectedDate(value)

            setPassbookData((prevData) => ({
                ...prevData,
                start_date: value,
                end_date: null,
            }))
        } else {
            setPassbookData((prevData) => ({
                ...prevData,
                end_date: value
            }))
        }
    })
    const endDate = passbookdata?.end_date ? moment(passbookdata?.end_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const getPassBookData = (async () => {
        let data = {
            pageNo: page + 1,
            limit: rowsPerPage,
            startDate: passbookdata?.start_date ? moment(passbookdata?.start_date).format("YYYY-MM-DD") : getDateRange(activeTab),
            endDate: endDate,
            token: userToken
        }
        dispatch(resetTransactionList())
        setLoading(true)
        let res = await dispatch(fetchPassbookTransaction(data))
        setLoading(false)
        console.log(res)
    })
    useEffect(() => {

        getPassBookData()
    }, [page, resetdateFlag, rowsPerPage])

    const HandleSearchTransation = (() => {
        let valid = validateMisfield()
        if (valid) {
            setPage(0)
            getPassBookData()
        }

    })
    const HandleResetDate = (() => {
        setPage(0)
        setPassbookData({
            start_date: "",
            end_date: "",
        })
    })
    const HandleFilterData = ((data) => {
        if (passbookdata?.start_date || passbookdata?.end_date) {
            setResetDateFlag((prev) => !prev)
        }
        setPassbookData({
            start_date: "",
            end_date: "",
        })
        setPage(0)
    })
    const setRecordsPerPage = ((val) => {
        setRowsPerPage(val)
    })

    useEffect(() => {
        // resetDate When mounting the page
        setPassbookData({
            start_date: "",
            end_date: "",
        })
        setPage(0)
    }, [])

    return (
        <div className='passBook_container'>
            {/* <div className='filter_buttons'>
                <button className={`filter_btn ${activeTab=="1M"?"active":""}`} onClick={()=>HandleFilterData("1M")}>Current Month</button>
                <button className={`filter_btn ${activeTab=="3M"?"active":""}`}onClick={()=>HandleFilterData("3M")}>3 Month</button>
                <button className={`filter_btn ${activeTab=="6M"?"active":""}`}onClick={()=>HandleFilterData("6M")}>6 Month</button>
            </div> */}
            <div className='passbook_dateField'>
                <div className="input_group_pform">
                    <div className="inputGropOne_form inputGrop">
                        <Calender
                            className="pForm_datePicker"
                            placeholder="Select Policy Start Date"
                            name="misStartDate"
                            id="policyStartDate"
                            maxDate={true}
                            value={passbookdata.start_date}
                            changeEvent={(e) => HanldeFormData(e)}
                            errMsg={errors?.misStartDate}
                            errClassName={errors?.misStartDate ? "input-err" : ""}
                        />
                    </div>

                    <div className="inputGropOne_form inputGrop">
                        <Calender
                            className="pForm_datePicker"
                            placeholder="Select Policy End Date"
                            name="misEndDate"
                            id="policyStartDate"
                            minDate={min}
                            maxDate={selectedDate}
                            isDisabled={!passbookdata.start_date}
                            value={passbookdata.end_date}
                            changeEvent={(e) => HanldeFormData(e)}
                            errMsg={errors?.misEndDate}
                            errClassName={errors?.misEndDate ? "input-err" : ""}
                        />
                    </div>

                    <section className="btnContainer_pform">
                        <Button text="Reset" className="button btn_pForm secondry_btn" event={() => HandleFilterData("1M")} />

                        <Button text="Search" className="button btn_pForm" event={HandleSearchTransation} />

                    </section>
                </div>
            </div>

            <div className='transaction_list'>
                {loading ? (
                    Array.from({ length: rowsPerPage }).map((_, index) => <SkeletonLoader key={index} />)
                ) : (
                    passBookDataT?.data?.data?.transaction.length==0 ?<h2 style={{textAlign:"center"}}>No Records Found</h2> :    passBookDataT?.data?.data?.transaction.map((data, index) => (
                        <div key={index} className="transaction-card">

                            <div className="transaction-details">
                                {/* <p>{new Date(data?.transaction_date).toLocaleString()}</p> */}
                                <div className="transaction-header">
                                    <p>{data?.comment?.split("-") && data?.comment?.split("-")[1]}</p>
                                    <span>{data?.comment?.split("-") ? data?.comment?.split("-")[0] : data?.comment || "-------NA-------"}</span>

                                </div>
                                <p className={` ${data?.transaction_type === 'debit' ? 'debitttt' : 'creditttt'}`}>
                                    {`${data?.amount}-${data?.transaction_type === 'debit'?"Dr":"Cr"}`}
                                </p>
                                <p>{`${data?.transaction_date?.slice(8, 10)}-${data?.transaction_date?.slice(5, 7)}-${data?.transaction_date?.slice(0, 4)} ${data?.transaction_date.slice(11, 19)}`}</p>
                                {/* <span className={`transaction-type ${data?.transaction_type.toLowerCase()}`}>
                                    {data?.transaction_type}
                                </span> */}
                                <p className="running-total"> Bal: <span className="balance">{data?.running_total}</span></p>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Stack spacing={2} alignItems="center" style={{ marginTop: "1rem" }}>

                {
                    totalCount?.total >= 10 && 
                    <div className="pagination-container">
                    <label htmlFor="recordsPerPage">Records per page:</label>
                    <select
                        id="recordsPerPage"
                        value={rowsPerPage}
                        onChange={(e) => {
                            setPage(0)
                            setRecordsPerPage(e.target.value)
                        }}
                        className="dropdown-select"
                    >
                        {totalCount?.total >= 10 && <option value="10">10</option>}
                        {totalCount?.total > 20 && <option value="20">20</option>}
                        {totalCount?.total > 50 && <option value="50">50</option>}
                        {totalCount?.total > 100 && <option value="100">100</option>}



                    </select>
                </div>
                }
               
                <Pagination
                    count={Math.ceil(totalCount?.total / rowsPerPage)}
                    page={page + 1}
                    onChange={handleChangePage}
                />
                <Stack direction="row" spacing={2} />
            </Stack>
        </div>

    )
}
const SkeletonLoader = () => (
    <div className='transaction-card skeleton'>
        <div className='transaction-details'>
            <div className='skeleton-box' style={{ width: '20%', height: '13px' }}></div>
            <div className='skeleton-box' style={{ width: '20%', height: '13px' }}></div>
            <div className='skeleton-box' style={{ width: '20%', height: '13px' }}></div>
            <div className='skeleton-box' style={{ width: '20%', height: '13px' }}></div>
        </div>
    </div>
);
export default Passbook