import { createSlice } from "@reduxjs/toolkit";
import { mergepdfAction,pdfReaderAction } from "../Actions/mergepdfAction";


export const mergepdfReducer = createSlice({
  name: "mergepdf",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(mergepdfAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(mergepdfAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(mergepdfAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});


export const mergepdfReaderReducer = createSlice({
  name: "Readpdf",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  reducers: {
    resetPdfReadertDetails: (state, action) => {
        state.data = undefined
    }
},
  extraReducers: (builder) => {
    builder.addCase(pdfReaderAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(pdfReaderAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(pdfReaderAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const { resetPdfReadertDetails } = mergepdfReaderReducer.actions;