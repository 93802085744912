import React from "react";
import "./Button.css";

const Button = (props) => {
  const { text, className, classNameImg, style, name, event, icons, spanTxt,isdisable } =
    props;

  return (
    <button className={className} name={name} style={style} onClick={event} disabled={isdisable}>
      {name!=="nextCalci" && 
       <div className={classNameImg}>
       <img src={icons} alt="" srcSet="" />
     </div>
      }
      <span> {spanTxt} </span> {text}
      {name==="nextCalci" && 
       <div className={classNameImg}>
       <img src={icons} alt="" srcSet="" />
     </div>
      }
     
    </button>
  );
};

export default Button;
