import { createSlice } from "@reduxjs/toolkit";
import { fetchPassbookTransaction,fetchCalciAction ,fetchCalciPremiumAction} from "../Actions/fetchPassbookAction";
 export const  PassbookTransactionReducer = createSlice({
    name: "getPassbookTransaction",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetTransactionList: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPassbookTransaction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPassbookTransaction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchPassbookTransaction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const  CalciReducer = createSlice({
    name: "getCalciAction",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetTransactionList: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCalciAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCalciAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchCalciAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});
export const  CalciPremiumReducer = createSlice({
    name: "getCalciPremium",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetPremiumData: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCalciPremiumAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCalciPremiumAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchCalciPremiumAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});
export const { resetTransactionList } = PassbookTransactionReducer.actions;
export const { resetPremiumData } = CalciPremiumReducer.actions;
