import React from 'react'
import Navbar from '../../Layouts/Navbar/Navbar'
import "./NewCalci.css"
import Calculator from '../User-Profile/Calculator/Calculator'

const NewCalci = () => {
  return (
    <>
     <Navbar/>
     <div className='main_container'>
       
       <div className='calci_container'>
       <Calculator/>
       </div>
      
   </div>
    </>
   
  )
}

export default NewCalci