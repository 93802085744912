import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchPassbookTransaction = createAsyncThunk(
    "getPassbookTransaction",
    async (bodyData) => {
        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_getPassbookRecord?pageNo=${bodyData?.pageNo}&limit=${bodyData?.limit}&startDate=${bodyData?.startDate}&endDate=${bodyData?.endDate}`,
            {
                method: "GET",  
                headers: new Headers({
                    "Authorization": `${bodyData?.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);

export const fetchCalciAction = createAsyncThunk(
    "getCalciAction",
    async (bodyData) => {
        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_motorPremuimCalculater?type=dropdown data`,
            {
                method: "GET",  
                headers: new Headers({
                    "Authorization": `${bodyData?.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);

export const fetchCalciPremiumAction = createAsyncThunk(
    "getCalciPremium",
    async (bodyData) => {
        let data=bodyData?.data
        const params = new URLSearchParams(data).toString()
      
console.log(data,"bodyData");

        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_motorPremuimCalculater?type=premium data&${params}`,
            // `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_motorPremuimCalculater?type=premium data&vehicleType=${bodyData?.vehicleType}&policy_type=${bodyData?.policy_type}&zone_type=${bodyData?.zone_type}&cc_ranges=${bodyData?.cc_ranges}&idv=${bodyData?.idv}&registrationDate=${bodyData?.registrationDate}&fuel_type=${bodyData?.fuel_type}&claim=${bodyData?.claim}&ncbprecent=${bodyData?.ncbprecent}&discount=${bodyData?.discount}&Selectedaddons:${bodyData?.Selectedaddons}`,
            {
                method: "GET",  
                headers: new Headers({
                    "Authorization": `${bodyData?.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);