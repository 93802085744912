import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../Assets/Images/calender.svg";
import arrowLeft from "../../../Assets/Images/arrowLeft.svg";
import arrowRight from "../../../Assets/Images/arrowRight.svg";

export default function Calender(props) {
  const {
    value,
    changeEvent,
    className,
    id,
    name,
    label,
    placeholder,
    classNameThree,
    minDateValue,
    maxDateValue,
    businessType,
    calenderType,
    isDisabled,
    errClassName,
    errMsg,
    minDate,
    maxDate
  } = props;

  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const currentYear = new Date().getFullYear();
  const years = [];
  let yearVal=name=="misStartDate"?2024:1940
  for (let year = yearVal; year <= currentYear; year++) {
    years.push(year);
  }

  years.reverse();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ValidDate = value && !isNaN(new Date(value).getTime());
  const selectedValidDate = ValidDate ? new Date(value) : "";
  let minDateShow = minDate && name == "misEndDate" ? minDate : minDate ? new Date() : new Date(Math.min(...years), 0, 1)
  return (
    <div className="customDatepickerContainer-parent">
      <div className="customDatepickerContainer">
        {
          (value !== null && value !== undefined) && (
            <p className="dropdown-header" >{placeholder}</p>
          )
        }
        <DatePicker
          portalId="root-portal"
          readOnly={isDisabled | false}
          dateFormat={"dd-MMM-yyyy"}
          className={
            errClassName
              ? className + " " + errClassName + " calenderClass"
              : className + " calenderClass"
          }
          selected={selectedValidDate}
          id={id}
          autoComplete="off"
          placeholderText={placeholder}
          name={name}
          onChange={(date) => {
            setSelectedDate(date);
            changeEvent({
              target: {
                name: name,
                value: date,
              },
            });
            setShowDatePicker(false);
          }}
          minDate={minDateShow}
          maxDate={name == "misStartDate" ? new Date() : name == "misEndDate" ? maxDate : new Date(Math.max(...years), 11, 31)}
          onClose={() => setShowDatePicker(false)}
          shouldCloseOnSelect={true}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="calender-container">
              <div className="calender-options-container">
                <select
                  className="custom-select-style"
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className="custom-select-style"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="calender-btns-container">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className="btn-calender"
                >
                  <img src={arrowLeft} alt="" srcSet="" />
                </button>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className="btn-calender"
                >
                  <img src={arrowRight} alt="" srcSet="" />
                </button>
              </div>
            </div>
          )}
        />
        {value && label ? <p className="datepicker-label">{label}</p> : ""}
        <img
          src={calender}
          className={classNameThree ? classNameThree : "calenderSvg"}
          alt=""
          srcSet=""
        />
      </div>
      {
        errMsg && (
          <p className="err-msg" >{errMsg}</p>
        )
      }
    </div>
  );
}
