
import { createAsyncThunk } from "@reduxjs/toolkit";
// https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_getTransactionList

export const fetchTransactionList = createAsyncThunk(
    "fetchTransactionList",
    async (bodyData) => {

        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_getTransactionList`,
            // 'https://i9f73efxg6.execute-api.us-east-1.amazonaws.com/uat/getTransaction',
            {
                method: "POST",
                body: JSON.stringify({ pageNo: `${bodyData?.pageNumber}`, searachQuery: `${bodyData?.serachValue}`, limit: `${bodyData?.pagelimit}` }),
                headers: new Headers({
                    "Authorization": `${bodyData?.userToken}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);