import React, { useState } from 'react'
import InputFields from "../../../InputField/TextField/InputField";
import { useDispatch, useSelector } from 'react-redux';
import Calender from "../../../InputField/Calender/Calender";
import Button from "../../../InputField/Button/Button";
import { sendEmisReoprtAction } from '../../../../Redux/Actions/SendEmail';
import ButtonLoader from '../../../Layouts/Loader/Button Loader/ButtonLoader';
import { ToastContainer, toast } from "react-toastify";
import "./MisReport.css"
const MisReport = ({ errors, setErrors, MisValue, setMisValue }) => {
    const dispatch = useDispatch()
    const userToken = useSelector((state) => {
        return state?.getLoggedInStatus?.data?.loginData?.token;
    });

    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState(new Date(MisValue.start_date));
    const [min, setMin] = useState(false)
    React.useEffect(() => {
        if (selectedDate && !isNaN(new Date(selectedDate))) {
            const newMaxDate = new Date(new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 92));
            const today = new Date();
            setSelectedDate(newMaxDate > today ? today : newMaxDate);
            if (newMaxDate > today) {
                setMin(selectedDate)
            }

        } else {
            setSelectedDate(null)
        }
    }, [MisValue.start_date]);
    const validateMisfield = () => {
        const newErrors = {};
        let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        if (!MisValue.start_date) {
            newErrors.misStartDate = "Start Date is required";
        }
        if (!MisValue.end_date) {
            newErrors.misEndDate = "End Date is required";
        }
        if (!MisValue.email) {
            newErrors.misEmail = "Email  is required";
        }
        if (MisValue.email && !regexEmail.test(MisValue.email)) {
            newErrors.misEmail = "Email is Invalid";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const HanldeFormData = ((e) => {
        const { name, value, checked } = e?.target || {};
        if (name == "misStartDate") {
            setSelectedDate(value)

            setMisValue((prevData) => ({
                ...prevData,
                start_date: value,
                end_date: null,
            }))
        } else {
            setMisValue((prevData) => ({
                ...prevData,
                end_date: value
            }))
        }
    })
    const handleInputChange = ((e) => {
        const { name, value, checked } = e?.target || {};
        setMisValue((prevData) => ({
            ...prevData,
            email: value
        }))
    })
    const HandleMisReport = (async () => {
        let valid = validateMisfield()
            ;
        if (valid) {
            setLoading(true)
            const StratDate = new Date(MisValue?.start_date);
            const endDate = new Date(MisValue?.end_date);
            const start_date = StratDate.toISOString().split('T')[0];
            const end_date = endDate.toISOString().split('T')[0];

            let data = {
                start_date: start_date,
                end_date: end_date,
                email: MisValue.email,
                token: userToken
            }


            let res = await dispatch(sendEmisReoprtAction(data))
            setLoading(false)
            if (res?.payload?.status == 1) {
                toast(
                    res?.payload?.msg
                        ? res?.payload?.msg
                        : "Something went Wrong, try after sometime!",
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            } else {
                toast.error(
                    res?.payload?.msg
                        ? res?.payload?.msg
                        : "Something went Wrong, try after sometime!",
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            }

        }
    })
    const ResetEvent = (() => {
        setMisValue((prevData) => ({
            ...prevData,
            start_date: "",
            end_date: "",
            email: "",
        }))
    })
    return (
        <>
            <ToastContainer />
            <div className='mis_Conatiner'>
                <div className='misTilte'>
                    <p>Management Infomation System (MIS)</p>
                    <div className='messagetext'>
                        <p>Enter Details</p>
                        <p>*Start and end dates must be within 90 days.</p>
                    </div>

                    <div className='horz_line'></div>
                </div>
                <div className="ProfileCard-container">
                    <div className="input_group_pform">
                        <div className="inputGropOne_form inputGrop ">
                            <Calender
                                className="pForm_datePicker"
                                placeholder="Select Policy Start Date"
                                name="misStartDate"
                                id="policyStartDate"
                                maxDate={true}
                                value={MisValue.start_date}
                                changeEvent={(e) => HanldeFormData(e)}
                                errMsg={errors?.misStartDate}
                                errClassName={errors?.misStartDate ? "input-err" : ""}
                            />
                        </div>
                        <div className="inputGropOne_form inputGrop ">
                            <Calender
                                className="pForm_datePicker"
                                placeholder="Select Policy End Date"
                                name="misEndDate"
                                id="policyStartDate"
                                minDate={min}
                                maxDate={selectedDate}
                                isDisabled={!MisValue.start_date ? true : false}
                                value={MisValue.end_date}
                                changeEvent={(e) => HanldeFormData(e)}
                                errMsg={errors?.misEndDate}
                                errClassName={errors?.misEndDate ? "input-err" : ""}
                            />
                        </div>
                    </div>
                    <div className="input_group_pform">
                        <div className="inputGropOne_form inputGrop ">
                            <InputFields
                                value={MisValue.email}
                                placeholder="Receiver  Email ID"
                                id="email"
                                type="text"
                                name="email"
                                errState={errors?.misEmail ? true : false}
                                errMsg={errors?.misEmail}
                                errClassName="input-err"
                                onChangeEvent={(e) => handleInputChange(e)}
                            />
                        </div>
                        <div className="inputGropOne_form inputGrop "></div>
                    </div>
                    <section className="btnContainer_pform">
                        <Button text="Reset" className="button btn_pForm  secondry_btn" event={ResetEvent} />
                        {loading ? <ButtonLoader /> :
                            <Button text="Send Report" className="button btn_pForm" event={HandleMisReport} />
                        }

                    </section>
                </div>
            </div>
        </>
    )
}
export default MisReport