import { createAsyncThunk } from "@reduxjs/toolkit";
export const mergepdfAction = createAsyncThunk(
    "mergepdf",
    async (data) => {
        const response = await fetch(
            "https://api.brilljust.com/upload-merge-file",
            //   'https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_uploadMergedDocument',
            {
                method: "POST",
                body: JSON.stringify({ data: data.data }),
                headers: new Headers({
                    "Authorization": `${data.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);

export const pdfReaderAction = createAsyncThunk(
    "Readpdf",
    async (data) => {
        const response = await fetch(
            "https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_pdfScanner",
            //   '',
            {
                method: "POST",
                body: JSON.stringify(data?.data),
                headers: new Headers({
                    "Authorization": `${data.token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);