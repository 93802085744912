import React from "react";
import "./PremiumBreakup.css"; // Import CSS file
import { useSelector } from "react-redux";
import BackArrow from "../../../../Assets/Images/BackArrow.svg"
import { Discount } from "@mui/icons-material";

const PremiumBreakup = ({ data, vehicleInfo, handleResetPremium }) => {
    const PremiumData = useSelector((state) => {
        return state.CalciPremium?.data?.data
    })
    const basicDetails = {
        Vehicle: vehicleInfo?.vehicleType,
        "Fuel Type": vehicleInfo?.fuel_type,
        "Cubic Capacity (CC)": vehicleInfo?.cc_ranges,
        "Policy Type": vehicleInfo?.policy_type,
        "Date of Registration": vehicleInfo?.registrationDate
            ? new Date(vehicleInfo.registrationDate)?.toLocaleDateString()
            : "N/A", // Convert Date object to readable format
        "Insured Declared Value (IDV)": vehicleInfo?.idv,
        Zone: vehicleInfo?.zone_type,
        Discount: vehicleInfo?.discount,
        "NCB Discount": vehicleInfo?.ncbprecent,

    };
    const AVal = PremiumData["premium breakup"]["Total OD Premium"] ? "A" : ''
    const BVal = PremiumData["premium breakup"]["Total Addons Amount"] ? "+B" : ""
    const CVal = PremiumData["premium breakup"]["Total TP Premium"] && (AVal || BVal) ? "+C" : PremiumData["premium breakup"]["Total TP Premium"] ? "C" : ""
    return (
        <div className="container">
            <div className="content">
                <div className="header">
                    <div onClick={() => handleResetPremium()} className="BackArrow_Cls">
                        <img src={BackArrow} alt="" height={20} />

                    </div>

                    <h2>
                        Premium Breakup (Final Premium : <span className="highlight">₹ {parseInt(PremiumData["premium breakup"]["Total Premium"] || 0)}</span>)
                    </h2>
                    <div className="buttons">
                        {/* <button className="outline-button">Share</button>
                        <button className="primary-button">Download</button> */}
                    </div>
                </div>

                <div className="card">
                    <div className="card-content">
                        <h3 className="section-title">Basic Details</h3>
                        <hr />
                        <div className="basic-details">
                            {Object.entries(basicDetails).map(([key, value]) => (
                                value ? (
                                    <div key={key} className="details-grid2">
                                        <span>{key}</span>
                                        <span className="premium_value">{value}</span>
                                    </div>
                                ) : null
                            ))}
                        </div>

                        {(PremiumData["premium breakup"]["Total OD Premium"]) &&
                            <div className="section">
                                <h3 className="section-title">Own Damage Cover (A)</h3>
                                <hr />
                                <div className="basic-details">


                                    <div className="details-grid2"><span>Own Damage</span> <span className="premium_value">₹  {parseInt(PremiumData["premium breakup"]["Basic OD Premium"])}</span></div>
                                    <div className="details-grid2"><span>Discount</span> <span className="premium_value">-₹ {parseInt(PremiumData["premium breakup"]["Discount"] || 0)}</span></div>
                                    <div className="details-grid2"><span>NCB Discount</span> <span className="premium_value">-₹ {parseInt(PremiumData["premium breakup"]["NCB Discount"] || 0)}</span></div>
                                    <div className="details-grid2 total"><span>Total Own Damage</span > <span className="premium_value">₹ {parseInt(PremiumData["premium breakup"]["Total OD Premium"])}</span></div>


                                </div>
                                <div >

                                </div>
                            </div>

                        }
                        {
                            (PremiumData["premium breakup"]["Total Addons Amount"]) &&
                            <div className="section">
                                <h3 className="section-title">Addon Coverage (B)</h3>
                                <hr />
                                <div className="">
                                    {Object.entries(PremiumData["premium breakup"]["aaddons premium"]).map(([key, value]) => (
                                        <div key={key} className="details-grid2">
                                            <span >{key}</span>
                                            <span className="premium_value">{parseInt(value)}</span>
                                        </div>
                                    ))}

                                </div>
                                <div className="details-grid2 total">
                                    <span>Total Addon Premium</span>
                                    <span>  ₹ {parseInt(PremiumData["premium breakup"]["Total Addons Amount"])}</span>
                                </div>


                            </div>

                        }


                        {PremiumData["premium breakup"]["Total TP Premium"] &&
                            <div className="section">
                                <h3 className="section-title">Third Party Cover (C)</h3>
                                <hr />
                                <div className="details-grid2">
                                    <span>Basic TP</span>
                                    <span className="premium_value"> ₹ {parseInt(PremiumData["premium breakup"]["Basic TP Premium"])}</span>
                                </div>
                                <div className="details-grid2">
                                    <span>{`Legal Liability (LL)`}</span>
                                    <span className="premium_value"> ₹ {parseInt(PremiumData["premium breakup"]["Legal Liability"])}</span>
                                </div>
                                <div className="details-grid2 total">
                                    <span>Total Third Party</span>
                                    <span> ₹ {parseInt(PremiumData["premium breakup"]["Total TP Premium"])}</span>
                                </div>

                            </div>
                        }


                        <div className="total-section">
                            <h3 className="section-title">Total Premium</h3>
                            <hr />
                            <div className="details-grid2">
                                <span>Net Payable ({`${AVal}${BVal}${CVal}`})</span>
                                <span className="premium_value"> ₹ {parseInt(PremiumData["premium breakup"]["Net Premium"])}</span>
                            </div>
                            <div className="details-grid2">
                                <span>GST</span>
                                <span className="premium_value"> ₹ {parseInt(PremiumData["premium breakup"]["Tax"])}</span>
                            </div>
                            <div className="details-grid2 final-total">
                                <span>Gross Premium </span>
                                <span> ₹ {parseInt(PremiumData["premium breakup"]["Total Premium"])}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PremiumBreakup;
