import React, { useState, useEffect } from "react";
import "./ViewPlans.css";
import Navbar from "../Layouts/Navbar/Navbar";
import ProductSelection from "../Pages/Product-Container/Select-Product/ProductSelection";
import Button from "../InputField/Button/Button";
import user from "../../Assets/Images/user.svg";
import CarDetails from "../Pages/Product-Container/Selected-Product-Container/Car-Details/CarDetails";
import EditVehicleDetails from "../Pages/Edit-Inputs-Container/Edit-Vehicle-Details/EditVehicleDetails";
import EditPersonalDetails from "../Pages/Edit-Inputs-Container/Edit-Personal-Details/EditPersonalDetails";
import DownloadPolicy from "../Pages/Download-Policy/DownloadPolicy";
import EditAddressDetails from "../Pages/Edit-Inputs-Container/Edit-Address-Details/EditAddressDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchMmvAction } from "../../Redux/Actions/fetchMmvAction";
import { resetVehicleData } from "../../Redux/Reducers/MmvReducer";
import Loader from "../Layouts/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import PaymentConfirmation from "../Pages/Payment-Confirmation/PaymentConfirmation";
import { purchaseAction } from "../../Redux/Actions/purchaseAction";
import { getWalletBalance } from "../../Redux/Actions/fetchWalletBalance";
import { fetchUserDetailsAction } from "../../Redux/Actions/getUserDetails";
import VehicleType from "../Pages/Vehicle-Type/VehicleType";
import { fectchEngineValidaionAction } from "../../Redux/Actions/fetchClientDetails";
import ButtonLoader from "../Layouts/Loader/Button Loader/ButtonLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const ViewPlans = () => {
  // ProductSelection, downloadPolicy, carDetails, editCarDetails, editAdressDetails
  const [RenderComponent, setRenderComponent] = useState("ProductSelection");
  const [CallForEdit, setCallForEdit] = useState(false);
  const [isButtonLoader, setisButtonLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [chekEngineLodar, setCheckEngineLoader] = useState(false)

  // modal functions:
  const [modalIsOpen, setIsOpen] = useState(false);

  // getUserToken
  const userToken = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData?.token;
  });
  const getLoggedInStatus = useSelector((data) => {
    return data?.getLoggedInStatus
  })
  let pdfReaderData = useSelector((data) => {
    return data?.pdfReaderData
  })

  // get purchase response
  const getPurchaseDetails = useSelector((state) => {
    return state?.getPurchaseDetails;
  });

  // navigation
  const navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const loading = useSelector((state) => {
    return state?.getMmvDetails?.isLoading;
  });

  const vehicleDetails = useSelector((state) => {
    return state?.getMmvDetails?.data?.data;
  });

  let modelDetails = vehicleDetails ? vehicleDetails?.model_details[0] : null;

  const [FormData, setFormData] = useState({
    productName: null,
    productId: null,
    vehicleNumber: null,
    businessType: null,
    companyName: null,
    make: modelDetails?.Brand ? modelDetails?.Brand : null,
    model: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    emailId: null,
    pincode: null,
    policyBuyPrice: null,
    policyNumber: null,
    policyStartDate: null,
    nomineeName: null,
    nomineeRelation: null,
    engineNumber: null,
    chassisNumber: null,
    addressLineOne: null,
    addressLineTwo: null,
    state: null,
    city: null,
    nomineeAge: null,
    vehicleType: null,
    cc: null,
    isCompleteCareChecked: false,
    crossRoad: true,
    defaultCCPlanAmount: 0,
    tenure:1
  });


  useEffect(() => {
    setFormData((prevItems) => ({
      ...prevItems,
      make: modelDetails?.Brand ? modelDetails?.Brand : FormData?.make,
      model: modelDetails?.Model ? modelDetails?.Model : FormData?.model,
    }));
  }, [vehicleDetails]);

  const dispatch = useDispatch();
console.log(FormData,"FormDataFormData");

  if (FormData.vehicleNumber !== null) {
    FormData.vehicleNumber = FormData.vehicleNumber.toUpperCase();
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [RenderComponent]);

  const [isGstOpt, setIsGstOpt] = useState(0)
  const [isUserDefaultCcPlanAmt, setIsUserDefaultCcPlanAmt] = useState(0)

  useEffect(() => {
    const fetchuser = async () => {
      let userDetails = await dispatch(fetchUserDetailsAction(userToken));
      if (userDetails.payload.status === 1) {
        setIsGstOpt(userDetails?.payload?.data?.is_opt_gst)
        setIsUserDefaultCcPlanAmt(userDetails?.payload?.data?.default_cc_plan_amount)
        setFormData((prevData) => ({
          ...prevData,
          defaultCCPlanAmount: userDetails?.payload?.data?.default_cc_plan_amount,
        }));
      }
    }
    fetchuser()
  }, [RenderComponent == "ProductSelection"])


  // console.log(...pdfReaderData?.data?.data,"ffsfhdfhafhakfh");

  const isCurrentOrFutureDate = (dateString) => {
    const givenDate = new Date(dateString);

    // Get the current date in IST
    const now = new Date();
    const istNow = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));

    // Set both dates to midnight for accurate date-only comparison
    givenDate.setHours(0, 0, 0, 0);
    istNow.setHours(0, 0, 0, 0);

    return givenDate >= istNow;
  };
  useEffect(() => {
    let CheckCurData = isCurrentOrFutureDate(pdfReaderData?.data?.data?.policy_start_date)
    setFormData((prevItems) => ({
      ...prevItems,
      addressLineOne:pdfReaderData?.data?.data?.addressLineOne!=="null" &&  pdfReaderData?.data?.data?.addressLineOne? pdfReaderData?.data?.data?.addressLineOne : "",
      addressLineTwo:pdfReaderData?.data?.data?.addressLineTwo!=="null" &&  pdfReaderData?.data?.data?.addressLineTwo? pdfReaderData?.data?.data?.addressLineTwo : "",
      chassisNumber: pdfReaderData?.data?.data?.chassisNumber!=="null" &&  pdfReaderData?.data?.data?.chassisNumber? pdfReaderData?.data?.data?.chassisNumber : "",
      city: pdfReaderData?.data?.data?.city!=="null" &&  pdfReaderData?.data?.data?.city? pdfReaderData?.data?.data?.city : "",
      companyName:  pdfReaderData?.data?.data?.companyName!=="null" &&  pdfReaderData?.data?.data?.companyName? pdfReaderData?.data?.data?.companyName : "",
      dob: pdfReaderData?.data?.data?.city!=="null" &&  pdfReaderData?.data?.data?.city? pdfReaderData?.data?.data?.city : "",
      emailId: pdfReaderData?.data?.data?.emailId!=="null" &&  pdfReaderData?.data?.data?.emailId? pdfReaderData?.data?.data?.emailId : "",
      engineNumber: pdfReaderData?.data?.data?.engineNumber!=="null" &&  pdfReaderData?.data?.data?.engineNumber? pdfReaderData?.data?.data?.engineNumber : "",
      firstName:  pdfReaderData?.data?.data?.firstName!=="null" &&  pdfReaderData?.data?.data?.firstName? pdfReaderData?.data?.data?.firstName : "",
      gender: pdfReaderData?.data?.data?.gender!=="null" &&  pdfReaderData?.data?.data?.gender? pdfReaderData?.data?.data?.gender : "",
      lastName: pdfReaderData?.data?.data?.lastName!=="null" &&  pdfReaderData?.data?.data?.lastName? pdfReaderData?.data?.data?.lastName : "",
      make: pdfReaderData?.data?.data?.make!=="null" &&  pdfReaderData?.data?.data?.make? pdfReaderData?.data?.data?.make : "",
      mobileNumber:  pdfReaderData?.data?.data?.mobileNumber!=="null" &&  pdfReaderData?.data?.data?.mobileNumber? pdfReaderData?.data?.data?.mobileNumber : "",
      model: pdfReaderData?.data?.data?.model!=="null" &&  pdfReaderData?.data?.data?.model? pdfReaderData?.data?.data?.model : "",
      nomineeAge: pdfReaderData?.data?.data?.nomineeAge!=="null" &&  pdfReaderData?.data?.data?.nomineeAge? pdfReaderData?.data?.data?.nomineeAge : "",
      nomineeName: pdfReaderData?.data?.data?.nomineeName!=="null" &&  pdfReaderData?.data?.data?.nomineeName? pdfReaderData?.data?.data?.nomineeName : "",
      nomineeRelation: pdfReaderData?.data?.data?.nomineeRelation!=="null" &&  pdfReaderData?.data?.data?.nomineeRelation? pdfReaderData?.data?.data?.nomineeRelation : "",
      pincode: pdfReaderData?.data?.data?.pincode!=="null" &&  pdfReaderData?.data?.data?.pincode? pdfReaderData?.data?.data?.pincode : "",
      policyNumber: pdfReaderData?.data?.data?.policyNumber!=="null" &&  pdfReaderData?.data?.data?.policyNumber? pdfReaderData?.data?.data?.policyNumber : "",
      state: pdfReaderData?.data?.data?.state!=="null" &&  pdfReaderData?.data?.data?.state? pdfReaderData?.data?.data?.state : "",
      policyStartDate: CheckCurData ? pdfReaderData?.data?.data?.policy_start_date : "",
    }));

  }, [pdfReaderData])



  // VALIDATION FOR ALTERNATE FORMS

  const validateVehicleDetails = () => {
    const newErrors = {};

    if (!FormData.businessType) {
      newErrors.businessType = "Business Type is required";
    }

    if (!FormData.vehicleNumber && FormData.businessType != "New") {
      newErrors.vehicleNumber = "Vehicle number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateMmvDetails = () => {
    const newErrors = {};

    if (!FormData.make) {
      newErrors.make = "Vehicle Brand is required";
    }

    if (!FormData.model) {
      newErrors.model = "Vehicle Model is required";
    }

    if (!FormData.engineNumber) {
      newErrors.engineNumber = "Engine Number is required";
    }

    if (!FormData.chassisNumber) {
      newErrors.chassisNumber = "Chassis Number is required";
    }

    if (!FormData.planBuyPrice) {
      newErrors.planBuyPrice = "Plan Price is required";
    }
    if (!FormData.policyNumber) {
      newErrors.policyNumber = "Policy Number is required";
    }


    let valueprice = getLoggedInStatus?.data?.loginData?.mobile == "7303350087" ? 599 : Number(FormData.minProductPrice)
    if (
      FormData.planBuyPrice
      && (Number(FormData.planBuyPrice) < valueprice ||
        Number(FormData.planBuyPrice) > FormData.maxProductPrice)
    ) {
      newErrors.planBuyPrice = `Range of Plan Price should be between ${valueprice} - ${FormData.maxProductPrice}`;
    }

    if (
      FormData.planBuyPrice
      && (Number(FormData.planBuyPrice) > FormData.minProductPrice &&
        Number(FormData.planBuyPrice) < FormData.maxProductPrice)
    ) {
      if ((Number(FormData.planBuyPrice) < FormData.minPlanAmount)) {
        newErrors.planBuyPrice = `Plan Price should be greater than ${FormData.minPlanAmount}`;
      }
    }

    if (!FormData.masterPolicyNumber) {
      newErrors.masterPolicyNumber = "Master policy number is required";
    }

    if (!FormData.policyStartDate) {
      newErrors.policyStartDate = "Policy Start date is required";
    }

    if (!FormData.companyName) {
      newErrors.companyName = "Company Name is required";
    }

    if (!FormData.vehicleType) {
      newErrors.vehicleType = "Vehicle Type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePersonalDetails = () => {
    const newErrors = {};

    if (!FormData.firstName) {
      newErrors.firstName = "First Name is required";
    }
    if (!FormData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    }
    let regexMobile = /^[2-9]{1}[0-9]{9}$/;

    if (FormData.mobileNumber && !regexMobile.test(FormData.mobileNumber)) {
      newErrors.mobileNumber = "Mobile number is Invalid";
    }

    if (!FormData.gender) {
      newErrors.gender = "Gender is required";
    }

    if (!FormData.nomineeAge) {
      newErrors.nomineeAge = "Age is required";
    }

    if (!FormData.dob) {
      newErrors.dob = "Date of Birth is required";
    }

    // if (FormData.dob) {
    //   var userDOB = new Date(FormData.dob);
    //   var currentDate = new Date();
    //   var age = currentDate.getFullYear() - userDOB.getFullYear();

    //   if (
    //     currentDate.getMonth() < userDOB.getMonth() ||
    //     (currentDate.getMonth() === userDOB.getMonth() &&
    //       currentDate.getDate() < userDOB.getDate())
    //   ) {
    //     age--;
    //   }

    //   if (age < 18) {
    //     newErrors.dob = "You must be 18+ to proceed further";
    //   }

    //   if (age > 70) {
    //     newErrors.dob = "You must be below 70 years of age to proceed further";
    //   }
    // }
    if (FormData.dob) {
      var userDOB = new Date(FormData.dob);
      var currentDate = new Date();

      // Calculate the differences
      var years = currentDate.getFullYear() - userDOB.getFullYear();
      var months = currentDate.getMonth() - userDOB.getMonth();
      var days = currentDate.getDate() - userDOB.getDate();

      // Adjust for negative days
      if (days < 0) {
        months--;
        var previousMonth = currentDate.getMonth() - 1;
        if (previousMonth < 0) {
          previousMonth = 11;
        }
        var daysInPreviousMonth = new Date(
          currentDate.getFullYear(),
          previousMonth + 1,
          0
        ).getDate();
        days += daysInPreviousMonth;
      }
      if (months < 0) {
        years--;
        months += 12;
      }
// console.log(years,months,days,"daysdays")
// || (years === 18 && (months > 0 || days > 0))
      if (years < 18 ) {
        newErrors.dob = "You must be 18+ to proceed further";
      } else if (years > 70 || (years === 70 && (months > 0 || days > 0))) {
        newErrors.dob = "You must be below 70 years of age to proceed further";
      }
    }

    if (!FormData.nomineeName) {
      newErrors.nomineeName = "Nominee Name is required";
    }
    if (!FormData.lastName) {
      newErrors.lastName = "Last Name is required";
    }
    if (!FormData.emailId) {
      newErrors.emailId = "Email ID is required";
    }
    let regexEmail = /^\S+@\S+\.\S+$/;
    if (FormData.emailId && !regexEmail.test(FormData.emailId)) {
      newErrors.emailId = "Email is Invalid";
    }
    if (!FormData.nomineeRelation) {
      newErrors.nomineeRelation = "Nominee Relation is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateAddressDetails = () => {
    const newErrors = {};

    if (!FormData.addressLineOne) {
      newErrors.addressLineOne = "Address Line 1 is required";
    }
    if (!FormData.state) {
      newErrors.state = "State is required";
    }
    if (!FormData.pincode) {
      newErrors.pincode = "Pincode is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
    if (FormData.pincode && !pinregEx.test(FormData.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    if (!FormData.addressLineTwo) {
      newErrors.addressLineTwo = "Address Line 2 is required";
    }

    if (!FormData.city) {
      newErrors.city = "City is required";
    }

    if (FormData.isCompleteCareChecked === true && !isUserDefaultCcPlanAmt) {
      if (!FormData.cc) {
        newErrors.cc = "CC is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // HANDLER TO UPDATE THE FORM-DATA
  const HanldeFormData = (e, constantName) => {

    const { name, value, checked } = e?.target || {};


    if (name) {
      delete errors[name]
    }

    if (constantName === "productName") {
      setFormData((prevData) => ({
        ...prevData,
        productName: e.name,
        productId: e.id,
        minProductPrice: e.min_selling_price,
        maxProductPrice: e.max_selling_price,
        masterPolicyNumber: e.master_policy_no
      }));
      if (e) {
        setRenderComponent("SelectVehicleType");
      }
    } else if (name === "businessType") {
      if (value === "New") {
        dispatch(resetVehicleData());
        setFormData((prevData) => ({
          ...prevData,
          vehicleNumber: null,
        }));
      }
      setFormData((prevData) => ({
        ...prevData,
        businessType: value,
      }));
    } else if (constantName === "resetData") {
      setFormData((prevData) => ({
        ...prevData,
        vehicleNumber: null,
        businessType: null,
        make: null,
        companyName: null,
        model: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
        emailId: null,
        pincode: null,
        planBuyPrice: null,
        policyBuyPrice: null,
        policyStartDate: null,
        nomineeName: null,
        nomineeRelation: null,
        masterPolicyNumber: null,
        engineNumber: null,
        chassisNumber: null,
        addressLineOne: null,
        addressLineTwo: null,
        state: null,
        city: null,
        dob: null,
        gender: null,
        nomineeAge: null,
        vehicleType: null
      }));
    } else if (name === "mobileNumber") {
      if (value.length <= 10) {
        setFormData((prevItems) => ({
          ...prevItems,
          mobileNumber: value,
        }));
      }
    } else if (name === "model") {
      if (value.length <= 50) {
        setFormData((prevItems) => ({
          ...prevItems,
          model: value,
        }));
      }
    }

    else if (name === "pincode") {
      if (value.length <= 6) {
        setFormData((prevItems) => ({
          ...prevItems,
          pincode: value,
        }));
      }
    } else if (constantName === "vehicleType") {
      setRenderComponent("carDetails");
      setFormData((prevItems) => ({
        ...prevItems,
        vehicleType: e?.type,
        minPlanAmount: e?.minamount,
      }));
    } else if (name === "isCompleteCareChecked") {
      setFormData((prevItems) => ({
        ...prevItems,
        isCompleteCareChecked: checked ? true : false,
      }))
      if (checked === false) {
        setFormData((prevItems) => ({
          ...prevItems,
          cc: "",
        }))
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: name === "policyStartDate" || name === "dob" ? value : value.toUpperCase(),
      }));
    }
  };

  async function EngineValidation(data2) {
    setCheckEngineLoader(true)
    let response = await dispatch(fectchEngineValidaionAction({
      data: data2
    }))
    return response

  }
  // PAGE REVEAL FUNCTIONS
  const setComponentToRender = (componentName) => {

    setCallForEdit(false);
    switch (componentName) {
      case "navbar":
        setRenderComponent("ProductSelection");
        break;

      case "cardetails":
        const isValidVehicleDetails = validateVehicleDetails();
        if (FormData.businessType != "New") {
          if (isValidVehicleDetails) {
            dispatch(fetchMmvAction(FormData.vehicleNumber));
            if (!loading) {
              setRenderComponent("editCarDetails");
            }
          }
        } else {
          setRenderComponent("editCarDetails");
        }
        break;

      case "editCarDetails":
        const isValidMmvDetails = validateMmvDetails();
        let data = {
          engine: FormData?.engineNumber,
          chassisNumber: FormData?.chassisNumber
        }
        if (isValidMmvDetails) {
          EngineValidation(data).then((values) => {
            if (values?.payload?.data?.chassis || values?.payload?.data?.engine) {
              if (values?.payload?.data?.chassis) {
                setErrors({ ...errors, chassisNumber: values?.payload?.data?.msgOne || "chassis Number is alreay exist" })
              }
              if (values?.payload?.data?.engine) {
                setErrors({ ...errors, engineNumber: values?.payload?.data?.msgTwo || "Engine Number is alreay exist" })
              }
              setCheckEngineLoader(false)

            } else if (values?.payload?.data?.chassis == false && values?.payload?.data?.engine == false) {
              setRenderComponent("editPersonalDetails");
              setCheckEngineLoader(false)
            }
          }).catch((errors) => {
            console.log(errors, "errors")
            setCheckEngineLoader(false)
          })


        }
        break;

      case "editPersonalDetails":
        const isValid = validatePersonalDetails();
        if (isValid) {
          setRenderComponent("editAdressDetails");
        }
        break;

      case "editAddressDetails":
        const isValidAddress = validateAddressDetails();
        if (isValidAddress) {
          openModal();
        }
        break;

      case "prevPolicyDetails":
        setRenderComponent("editPersonalDetails");
        break;

      default:
        break;
    }
  };

  // BACK BTN FUN
  const handleBackBtn = (componentName) => {
    // alert(componentName);
    setCallForEdit(false);
    switch (componentName) {

      case "SelectVehicleType":
        setRenderComponent("ProductSelection");
        break;

      case "cardetails":
        setRenderComponent("SelectVehicleType");
        break;

      case "editCarDetails":
        setRenderComponent("carDetails");
        break;

      case "prevPolicyDetails":
        setRenderComponent("editCarDetails");
        break;

      case "editPersonalDetails":
        setRenderComponent("editCarDetails");
        break;

      case "editAddressDetails":
        setRenderComponent("editPersonalDetails");
        break;

      case "downloadPolicy":
        setRenderComponent("editAdressDetails");
        break;

      case "dropToProductPage":
        setRenderComponent("productPage");
        break;

      default:
        break;
    }
  };

  const handleEditTriggers = (componentName) => {
    if (componentName === "editCarDetails") {
      setRenderComponent("editCarDetails");
      setCallForEdit(true);
    } else if (componentName === "prevPolicyDetails") {
      setRenderComponent("prevPolicyDetails");
      setCallForEdit(true);
    } else {
      setRenderComponent("editPersonalDetails");
      setCallForEdit(true);
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  //Fix one day back date
  // {...FormData,dob:getFormatedDate(FormData?.dob),policyStartDate:getFormatedDate(FormData?.policyStartDate)},
  const getFormatedDate = ((date) => {
    const localDate = new Date(date);
    const offsetDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    const formattedDate = offsetDate.toISOString();
    return formattedDate
  })

  const paymentConfirmed = async () => {
    setisButtonLoader(true);
    let dispatchPurchase = await dispatch(
      purchaseAction({
        data: {
          data: {
            proposal: FormData
          },
        },
        userToken: userToken,
      })
    );
    if (dispatchPurchase.payload.status === 1) {
      await dispatch(getWalletBalance(userToken));
      setTimeout(() => {
        setisButtonLoader(false);
        setRenderComponent("downloadPolicy");
        closeModal();
      }, 2000);

    } else {
      setisButtonLoader(false);
      alert("Something Went Wrong!, Please try again.");
      closeModal();
    }
  };

  return (
    <>
      {loading === true ? <Loader /> : null}

      {/* payment confirmation modal */}

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <PaymentConfirmation
            closeModal={closeModal}
            paymentConfirmed={paymentConfirmed}
            isLoading={getPurchaseDetails?.isLoading}
            isApiLoaded={isButtonLoader}
          />
        </Modal>
      </div>

      <Navbar
        component={
          <Button
            text="My Account"
            icons={user}
            className="btn-myAccount"
            classNameImg="img-circle"
            event={(e) => navigate("/My-Account")}
          />
        }
        setComponentToRender={setComponentToRender}
        HanldeFormData={HanldeFormData}
      />

      {/* SELECT PRODUCT */}

      {RenderComponent === "ProductSelection" && (
        <ProductSelection event={HanldeFormData} data={FormData} />
      )}

      {/* SELECT VEHICLE TYPE */}

      {RenderComponent === "SelectVehicleType" && (
        <VehicleType event={HanldeFormData} data={FormData} handleBackBtn={handleBackBtn} />
      )}

      {/* vehicleNumber */}

      {RenderComponent === "carDetails" && (
        <CarDetails
          FormData={FormData}
          HanldeFormData={HanldeFormData}
          setComponentToRender={setComponentToRender}
          handleBackBtn={handleBackBtn}
          chekEngineLodar={chekEngineLodar}
          errors={errors}
        />
      )}

      {/* EDIT-CAR-DETAILS */}

      {RenderComponent === "editCarDetails" && (
        <EditVehicleDetails
          FormData={FormData}
          getLoggedInStatus={getLoggedInStatus}
          setFormData={setFormData}
          HanldeFormData={HanldeFormData}
          setComponentToRender={setComponentToRender}
          handleBackBtn={handleBackBtn}
          CallForEdit={CallForEdit}
          errors={errors}
          chekEngineLodar={chekEngineLodar}
        />
      )}

      {/* EditPersonalDetails */}

      {RenderComponent === "editPersonalDetails" && (
        <EditPersonalDetails
          FormData={FormData}
          HanldeFormData={HanldeFormData}
          setComponentToRender={setComponentToRender}
          handleBackBtn={handleBackBtn}
          CallForEdit={CallForEdit}
          errors={errors}
        />
      )}

      {/* AddressDetails */}

      {RenderComponent === "editAdressDetails" && (
        <EditAddressDetails
          FormData={FormData}
          HanldeFormData={HanldeFormData}
          setFormData={setFormData}
          setComponentToRender={setComponentToRender}
          handleBackBtn={handleBackBtn}
          CallForEdit={CallForEdit}
          errors={errors}
        />
      )}

      {/* Download - PAGE */}

      {RenderComponent === "downloadPolicy" && (
        <DownloadPolicy
          FormData={FormData}
          HanldeFormData={HanldeFormData}
          setComponentToRender={setComponentToRender}
          handleBackBtn={handleBackBtn}
          handleEditTriggers={handleEditTriggers}
          policyNumber={getPurchaseDetails?.data?.data?.certificateNo}
        />
      )}
    </>
  );
};

export default ViewPlans;
