import { createAsyncThunk } from "@reduxjs/toolkit";

export const purchaseAction = createAsyncThunk(
    "purchaseAction",
    async (data) => {
        const response = await fetch(
            // `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_purchaseProduct`
            "https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_purchaseProduct_New",
            {
                method: "POST",
                body: JSON.stringify(data.data),
                headers: new Headers({
                    "Authorization": `${data.userToken}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);