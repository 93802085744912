import React from "react";
import tick from "../../Assets/Images/success.svg";
import "./stepper.css"

const Stepper = ({ activeStep ,setActiveStep,validateVehicleDetails}) => {
  console.log(activeStep,"activeStepactiveStep");
  
  const steps = [
    { label: "Vechile Type", stepNumber: 1 },
    { label: "Basic Details", stepNumber: 2 },
    { label: "Add-Ons", stepNumber: 3 },
    { label: "NCB & Discount", stepNumber: 4 },
    // { label: "Vehicle Details", stepNumber: 5 },
  ];

  const setActiveVal=((val)=>{
    if(val>2){
    let flag=validateVehicleDetails()
    flag && setActiveStep(val)
    }else{
      setActiveStep(val)
    }
    
  })

  return (
    <div className="stepperContainer">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`stepperDiv ${activeStep === index + 1 ? "stepperDiv_active" : ""
            }`}
          onClick={()=>setActiveVal(step?.stepNumber)}
        >
          <div className="signal_pform">
            {activeStep > index + 1 ? (
              <img src={tick} alt="Completed" />
            ) : (
              <p>{index + 1}</p>
            )}
          </div>
          <div className="title_stepper">
            <p className="small_text_stepper">Step {step.stepNumber}</p>
            <p>{step.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;


