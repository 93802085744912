import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./Components/Style/Global.css";
import Login from "./Components/Pages/Login/Login";
import ViewPlans from "./Components/ViewPlans/ViewPlans";
import { UseSelector, useSelector } from "react-redux";
import ClientAccount from "./Components/Pages/User-Profile/ClientAccount";
import NewCalci from "./Components/Pages/Calculator/NewCalci";

const Root = () => {

  const isAuthenticated = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.isLoggedIn
  })


  return (
    <>
      <HashRouter>
        <Routes>
        <Route path="/calculator" element={ <NewCalci/>}/>
          <Route path="/" element={
            isAuthenticated ? (
              <ViewPlans />
            ) : (
              <Login />
            )
          } />
          <Route path="/View-Plans" element={
            isAuthenticated ? (
              <ViewPlans />
            ) : (
              <Login />
            )
          } />
          <Route path="/My-Account" element=
            {
              isAuthenticated ? (
                <ClientAccount />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </HashRouter>
    </>
  );
};

export default Root;
